/** @format */

import Controller from '@ember/controller';
import { defer, reject } from 'rsvp';
import { buildValidations, validator } from 'ember-cp-validations';
import ValidationErrors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';
import { observer } from '@ember/object';
import { next } from '@ember/runloop';

const Validations = buildValidations({
	username: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Username',
	}),

	pin: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Pin',
	}),
});

export default Controller.extend(Validations, {
	queryParams: ['username', 'pin', 'is_platform_redirect'],
	username: null,
	pin: null,

	inputType: 'login',
	notifications: service(),
	currentUser: service(),
	serviceWorker: service('service-worker'),
	serverDown: observer('serviceWorker.serverDownLogin', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('serverDownLogin')) {
			this.notifications.error(
				'App currently having trouble connecting. Try again in a few minutes.',
				{
					cssClasses: 'notification--failure',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),
	internetDown: observer('serviceWorker.internetDownLogin', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('internetDownLogin')) {
			this.notifications.error(
				'App currently having trouble connecting to internet. Please check your connection.',
				{
					cssClasses: 'notification--error',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),

	init() {
		this._super(arguments);

		/* 
      Use next to run in the next runloop so the params can populate
      the username and pin attributes and then check for them and 
      attempt to login with them automagically
    */
		next(() => {
			if (this.username && this.pin) {
				this.currentUser.logout();
				this.handleLogin();
			}
		});
	},

	handleLogin() {
		this.set('errors', null);

		const username = this.username;
		const pin = this.pin;
		const errors = this.validations.get('messages');

		this.set('showError', true);
		if (errors.length > 0) {
			return reject();
		} else {
			const result = defer();

			this.send('login', username, pin, result);
			return result.promise;
		}
	},

	actions: {
		loginButtonPressed() {
			return this.handleLogin();
		},
	},
});
