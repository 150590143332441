import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Ember from 'ember';
import ENV from 'case-status/config/environment';
import LogRocket from 'logrocket';
import { storageFor } from 'ember-local-storage';
import EmberError from '@ember/error';

export default Route.extend(AuthenticatedRouteMixin, {
	preferences: storageFor('preferences'),

	ajax: service(),
	session: service(),
	thirdParty: service(),
	permissions: service(),
	appSec: service(),
	authenticatedUserType: alias('session.data.authenticated.user_type'),

	currentUser: service('current-user'),
	targetPath: null,
	authenticationRoute: computed(
		'thirdParty.{integrationType,integrationId}',
		function () {
			const routeAfterInvalidation = `/login?integrationType=${this.thirdParty.integrationType}&integrationId=${this.thirdParty.integrationId}`;

			if (this.thirdParty.limited) {
				return routeAfterInvalidation;
			} else if (this.targetPath) {
				return `/login?redirectTo=${this.targetPath}`;
			} else {
				return '/login';
			}
		},
	),
	routeAfterAuthentication: computed(
		'session.data.authenticated.user_type',
		function () {
			const authenticatedUserType = this.get(
				'session.data.authenticated.user_type',
			);

			switch (authenticatedUserType) {
				case 'client':
					return 'app.client.cases';
				case 'member':
					return 'app.organization.cases';
				default:
					'app.firm.cases';
					break;
			}
		},
	),

	beforeModel(transition) {
		//* must set this before calling _super so that the potential redirect value is set
		//* by the time we compute authenticationRoute
		this.targetPath = transition.targetName;
		this._super(transition);
		// For users with multiple accounts we need to set the headers with the selected child account.
		// We pull the last selected account from the local storage preferance variable. If the user has
		// not selected an account, but has multiple accounts they will be redirected to the
		// select-account route. see afterModel()

		if (
			this.get('preferences.selectedAccount') &&
			this.get('preferences.selectedAccountType')
		) {
			this.set(
				'session.data.authenticated.user_type',
				this.get('preferences.selectedAccountType'),
			);
			this.set(
				'session.data.authenticated.user_id',
				this.get('preferences.selectedAccount'),
			);
		}
		const authenticatedUserType = this.session.data.authenticated.user_type;

		if (
			!this.targetPath.includes('app.admin') &&
			!this.targetPath.includes('app.third-party') &&
			!this.targetPath.includes('app.clio') &&
			!this.targetPath.includes('app.add-to-clio') &&
			!this.targetPath.includes('app.litify') &&
			!this.targetPath.includes('app.salesforce') &&
			!this.targetPath.includes('app.smokeball') &&
			!this.targetPath.includes('app.mycase') &&
			!this.targetPath.includes('app.merus-case') &&
			!this.targetPath.includes('app.docketwise')
		) {
			if (
				authenticatedUserType == 'member' &&
				!this.targetPath.includes('app.organization')
			) {
				return this.replaceWith('app.organization.cases');
			}
			// We do not want to mutate path if client is requesting a file
			if (
				authenticatedUserType == 'client' &&
				!this.targetPath.includes('app.client') &&
				!this.targetPath.includes('app.files') &&
				!this.targetPath.includes('app.videos')
			) {
				return this.replaceWith('app.client.cases');
			}

			if (
				(authenticatedUserType == 'attorney' ||
					authenticatedUserType == 'paralegal') &&
				!this.targetPath.includes('app.firm') &&
				!this.targetPath.includes('app.third_party') &&
				!this.targetPath.includes('app.files') &&
				!this.targetPath.includes('app.videos')
			) {
				return this.replaceWith('app.firm.cases');
			}
		}

		return this._loadCurrentUser();
	},

	sessionAuthenticated() {
		this._super(...arguments);
		this._loadCurrentUser();
	},

	sessionInvalidated() {
		if (!Ember.testing) {
			window.location.replace('/login');
		}
	},

	_loadCurrentUser() {
		if (!this.currentUser) {
			this.currentUser.logout();
		}
		return this.currentUser.load().catch(() => this.session.invalidate());
	},

	setupController(controller) {
		this._super(...arguments);

		this.controller.set('currentUser', this.currentUser);

		// Pull the user permissions and set them on the current-user service
		let permissionsUrl = `${ENV.host}/permissions`;
		this.ajax
			.request(permissionsUrl, {
				contentType: 'application/json',
			})
			.then((response) => {
				controller.set('currentUser.permissions', response);
			});
	},

	afterModel: function () {
		const user = this.get('currentUser.user');
		if (!user) {
			this.session.invalidate();
			return;
		}

		// If the user has multiple accounts and has not selected one we send them to a special route to
		// select an account to use
		if (
			this.get('currentUser.usersChildren').length > 1 &&
			!this.get('preferences.selectedAccount')
		) {
			return this.replaceWith('select-account');
		}

		const userId = user.get('id').toString();

		//* Only log on prod, not in iFrames, and for known (logged in) non-client users who are not in the download route
		if (
			ENV.environment === 'production' &&
			this?.router?.currentRouteName != 'download' &&
			!this?.thirdParty?.isIFrame &&
			user &&
			user.type != 'client'
		) {
			LogRocket.init('gni08x/case-status');

			LogRocket.identify(userId, {
				name: user.get('name'),
				email: user.get('emailAddress'),
				accountType: user.constructor.modelName,
				firm: user.get('firm.name'),
				firmId: user.get('firm.id'),
				organization: user.get('organization.name'),
				orgId: user.get('organization.id'),
			});
		}

		this.appSec.check();
	},

	actions: {
		/**
		 *Gatekeeper is intended to be used to check permissions for route access and redirect the user accordingly based on their available permissions if access checked is denied
		 *@param gate target permission to check (ie: settings_user_account)
		 *@param fallback optional fallback route to redirect to when rejected
		 *@param callbacks functions to be called upon success
		 */
		async gatekeeper(gate = '', fallback = '', ...callbacks) {
			if (typeof gate !== 'string') {
				throw new EmberError(
					`Gatekeeper Error: incorrect param type of ${typeof gate}, must be a string`,
				);
			} else if (!gate) {
				throw new EmberError('Gatekeeper Error: missing param');
			}

			//* firmOrg will help routing dynamically based on if the user is a client, member, attorney, or paralegal
			const firmOrg =
				this.get('currentUser.user.constructor.modelName') == 'client'
					? 'client'
					: this.get('currentUser.user.firm')
					? 'firm'
					: 'organization';

			if (!this.get(`permissions.user.${gate}`)) {
				//* If the user does not have access to the attempted gate
				if (fallback) {
					//* If a fallback gate is provided
					if (typeof fallback !== 'string') {
						throw new EmberError(
							`Gatekeeper Error: incorrect second param type of ${typeof gate}, must be a string`,
						);
					}

					//* replaceWith using the fallback gate (instead of transitionTo to eliminate the prohibited path from the browser history)
					this.replaceWith(`app.${firmOrg}.${fallback.replace(/\//gi, '.')}`);
					return false;
				} else {
					//* If no fallback gate is provided then redirect to the relative cases route
					this.replaceWith(`app.${firmOrg}.cases`);
					return false;
				}
			} else if (callbacks && callbacks.length) {
				//* result shall start as true and be passed to the first callback,
				//* result shall then be set as any returned value of the callback
				//* and then subsequently passes that to the next callback in a
				//* separated array of all previous results as well, for availability
				const result = [true];
				for (let i = 0; i < callbacks.length; i++) {
					const callback = callbacks[i];
					if (typeof callback === 'function') {
						//* await each callback to run them in order so each result
						//* can be passed in proper order as well
						const res = await callback(...result);
						result.shift(res);
					}
				}
			}
			// All checks pass and all callbacks called, if any, then return true.
			return true;
		},
	},
});
