/** @format */

export const integrations = {
	CLIO: 'clio',
	DOCKETWISE: 'docketwise',
	LITIFY: 'litify',
	MERUS_CASE: 'merus_case',
	CASEPEER: 'casepeer',
	MYCASE: 'mycase',
	SMARTADVOCATE: 'smartadvocate',
	SALESFORCE: 'salesforce',
	SMOKEBALL: 'smokeball',
	THIRD_PARTY: 'thirdParty',
};
