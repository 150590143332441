import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n\ttype='button'\n\tclass={{concat\n\t\t'ai-recommend-responses btn-small '\n\t\t(if @showAiRecommendations 'hidden' '')\n\t}}\n\t{{on 'click' (fn this.handleAiButtonClick @eligibleMessage)}}\n>\n\t<FaIcon @icon='wand-magic-sparkles' />\n\t<span class='text'>Recommend responses</span>\n</button>", {"contents":"<button\n\ttype='button'\n\tclass={{concat\n\t\t'ai-recommend-responses btn-small '\n\t\t(if @showAiRecommendations 'hidden' '')\n\t}}\n\t{{on 'click' (fn this.handleAiButtonClick @eligibleMessage)}}\n>\n\t<FaIcon @icon='wand-magic-sparkles' />\n\t<span class='text'>Recommend responses</span>\n</button>","moduleName":"case-status/components/ai-recommend-responses-button.hbs","parseOptions":{"srcName":"case-status/components/ai-recommend-responses-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';

export default class AiRecommendResponsesButtonComponent extends Component {
	@tracked error = null;
	@service ajax;
	@service store;
	@service notifications;
	@service featureGates;

	@action
	async generateRecommendedResponses() {
		const messageId = this.args.eligibleMessage?.id;
		this.args.updateActionInProgress(true);
		this.args.updateShowPlaceholderMessages(true);
		// Define the payload for the POST request
		const payload = {
			instructions: null,
		};
		const launchNewRecommendedResponsesModel = this.featureGates.eval('launch_new_recommended_responses_model')
		// Make the POST request to the API
		try {
			const response = await this.ajax.post(
				`${ENV.host}/recommended_responses/${messageId}?${launchNewRecommendedResponsesModel ? 'use_new_model=1' : ''}`,
				{
					data: payload,
				},
			);

			this.store.pushPayload(response);
			const recommendedResponses = response.data.map((rec) => {
				return this.store.peekRecord('recommended-response', rec.id);
			});

			if (this.args.onResponsesFetched) {
				this.args.onResponsesFetched(recommendedResponses);
			}
		} catch (error) {
			let errorMessage = 'An unknown error occurred';

			if (
				error.payload &&
				error.payload.errors &&
				error.payload.errors.length > 0
			) {
				errorMessage = error.payload.errors[0].detail;
			}

			this.notifications.error(`Error! ${errorMessage}`);
		}
		this.args.updateActionInProgress(false);
		this.args.updateShowPlaceholderMessages(false);
	}

	@action
	handleAiButtonClick(eligibleMessage) {
		this.args.onToggle();
		if (!this.args.fetchedResponses && !this.args.quotaExceeded) {
			this.generateRecommendedResponses(eligibleMessage?.id);
		}
	}
}
