import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';

export default ApplicationAdapter.extend({
	featureGateClient: service('feature-gates'),

	urlForQuery: function (query) {
		const launchNewRecommendedResponsesModel = this.get('featureGateClient').eval('launch_new_recommended_responses_model')
		if (query.messageId) {
			return `${this.host}/recommended_responses/${query.messageId}?${launchNewRecommendedResponsesModel ? 'by_batch=1' : ''}`;
		}
	},
});
