/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-get */
/* eslint-disable ember/no-classic-classes */
import { defer } from 'rsvp';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { debounce, next } from '@ember/runloop';
import { computed, observer } from '@ember/object';
import moment from 'moment';
import { captureException } from 'logrocket';
import ENV from 'case-status/config/environment';
import CasesMixin from '../../../mixins/controller/cases';
import { dynamicCaseLabel } from '../../../helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { createErrorIfFileTooBig } from '../../../helpers/validate-file-size';
import { resetTableLocation } from 'case-status/components/cases-table';
import { handleOptionsCallbacks } from 'case-status/constants/callbacks';
import ValidateData from 'case-status/constants/data-types';

const s3Env =
	ENV.environment == 'development' ? 'testing' : ENV.environment.toLowerCase();
const casesPerPage = 20;
const casesPerPageMarginOfError = 4;

function shouldShowMoreData() {
	const nb = $('.navbar');
	const nb2 = $('.navbar--secondary');
	const navbarHeight = nb.height() + nb2.height();
	// It is important that we use `window.innerHeight` below as this takes into account the browser's zoom settings
	const tableHeight = window.innerHeight - navbarHeight;

	const firstRow = $('.lt-row')[0];
	// This happens when viewing cases on the mobile web app
	if (firstRow === undefined) {
		return false;
	}
	// Find the row height preferring the clientHeight, but using the offsetHeight if clientHeight is zero
	const rowHeight = firstRow.clientHeight || firstRow.offsetHeight;

	// This gives rough estimate of number of rows we can display...
	// we add a few rows onto rowsToShow to err on the side of caution by loading more cases
	const rowsToShow = tableHeight / rowHeight + casesPerPageMarginOfError;
	return rowsToShow > casesPerPage;
}

export default Controller.extend(CasesMixin, {
	responsive: service(),
	mobile: service(),
	permissions: service(),
	thirdParty: service(),
	attorneyName: null,
	closed: false,
	removed: false,
	onHold: false,
	openAndActive: false,
	onboarding: false,
	noCaseManagers: false,
	selectAllCases: false,
	disableCaseSelect: true,
	selectedCases: null,
	deselectedCases: null,
	clientName: null,
	paralegalName: null,

	selectedCasesText: computed(
		'selectedCases',
		'selectAllCases',
		'meta.total_results',
		'deselectedCases',
		function () {
			if (this.selectedCases.length) {
				return `${this.selectedCases.length} ${dynamicCaseLabel([
					this.company,
					this.selectedCases.length !== 1,
				])}`;
			} else if (this.selectAllCases) {
				const selectedCaseCount =
					this.meta.total_results - this.deselectedCases.length;
				return `${selectedCaseCount} ${dynamicCaseLabel([
					this.company,
					selectedCaseCount !== 1,
				])}`;
			} else {
				return 'ERROR';
			}
		},
	),

	aiCaseQuotaUsed: computed(
		'permissions.firmSettings',
		'currentUser.user.firm.firmSettings.firmSettings',
		function () {
			if (!this.get('currentUser.isPortalCustomer')) {
				return;
			}

			const quota = this.get(
				'permissions.firmSettings.recommended_response_feature_case_quota',
			);
			const count = this.get(
				'permissions.firmSettings.recommended_response_feature_case_count',
			);
			if (quota === null || quota === undefined || count === undefined) {
				return;
			}

			const ratio = Number(count) / Number(quota);
			const caseQuota = Math.round(ratio * 100);
			return caseQuota <= 100 ? caseQuota : 100;
		},
	),

	maxUploadFileMB: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
			);
		},
	),

	queryChanged: observer('filters.{}', 'sort', function () {
		if (this.model) {
			this.model.clear();
			this.set('isLoading', true);
			debounce(this, this.fetchNewData, 500);
		}
	}),
	searchStringChanged: observer('searchString', function () {
		const searchString = this.searchString;

		this.send('filterUpdateCallback');

		switch (this.searchType) {
			case 'caseId':
				this.set('caseId', searchString);
				break;
			case 'client':
				this.set('clientName', searchString);
				break;
			case 'attorney':
				this.set('attorneyName', searchString);
				break;
			case 'paralegal':
				this.set('paralegalName', searchString);
				break;
		}
	}),
	searchType: 'client',
	searchTypeChanged: observer('searchType', function () {
		const searchString = this.searchString;

		if (searchString === '') {
			this.set('caseId', null);
			this.set('clientName', null);
			this.set('paralegalName', null);
			this.set('attorneyName', null);
			this.set('caseIds', null);
		}

		switch (this.searchType) {
			case 'caseId':
				this.set('caseId', searchString);
				this.set('clientName', null);
				this.set('paralegalName', null);
				this.set('attorneyName', null);
				this.set('caseIds', null);
				break;
			case 'client':
				this.set('clientName', searchString);
				this.set('paralegalName', null);
				this.set('attorneyName', null);
				this.set('caseId', null);
				this.set('caseIds', null);
				break;
			case 'attorney':
				this.set('attorneyName', searchString);
				this.set('clientName', null);
				this.set('paralegalName', null);
				this.set('caseId', null);
				this.set('caseIds', null);
				break;
			case 'paralegal':
				this.set('paralegalName', searchString);
				this.set('attorneyName', null);
				this.set('clientName', null);
				this.set('caseId', null);
				this.set('caseIds', null);
				break;
			default:
				this.set('caseId', null);
				this.set('clientName', null);
				this.set('paralegalName', null);
				this.set('attorneyName', null);
				this.set('caseIds', null);
				break;
		}
	}),

	bulkActionsContainerClass: computed(
		'selectAllCases',
		'selectedCases',
		'deselectedCases',
		'meta.total_results',
		function () {
			const selectedCases = this.selectedCases;
			const deselectedCases = this.deselectedCases;
			const selectAllCases = this.selectAllCases;
			const hideMoreBulkActions = this.hideMoreBulkActions;
			let cssClassString = 'container--table secondary-2 ';
			if (
				selectedCases.length ||
				(selectAllCases && this.meta.total_results - deselectedCases.length > 0)
			) {
				if (!hideMoreBulkActions) {
					cssClassString += 'table-bulk-select-state ';
				} else {
					cssClassString += 'less-actions ';
				}
			}
			return cssClassString;
		},
	),

	bulkActionsBtnClass: computed(
		'hideMoreBulkActions',
		'selectAllCases',
		'selectedCases',
		function () {
			const hideMoreBulkActions = this.hideMoreBulkActions;
			const selectAllCases = this.selectAllCases;
			const selectedCases = this.selectedCases;
			let cssClassString = '';
			if (selectedCases.length || selectAllCases) {
				cssClassString += 'bulk-actions-btn-text ';
				if (!hideMoreBulkActions) {
					cssClassString += 'bulk-actions-slider ';
				} else {
					cssClassString += 'hidden-s hidden-xs hidden-md ';
				}
				return cssClassString;
			}
			cssClassString += 'closed bulk-actions-btn-text bulk-actions-slider ';
			return cssClassString;
		},
	),

	bulkActionsMessageBtnClass: computed(
		'selectAllCases',
		'selectedCases',
		'deselectedCases',
		'meta.total_results',
		function () {
			const selectAllCases = this.selectAllCases;
			const selectedCases = this.selectedCases;
			const deselectedCases = this.deselectedCases;
			let cssClassString = '';
			if (
				selectedCases.length ||
				(selectAllCases && this.meta.total_results - deselectedCases.length > 0)
			) {
				cssClassString += 'bulk-actions-btn-text bulk-actions-slider ';
				return cssClassString;
			}
			cssClassString += 'closed bulk-actions-btn-text bulk-actions-slider ';
			return cssClassString;
		},
	),

	showAddCaseModal: false,
	showWelcomeModal: false,
	showIframeCaseFilters: false,

	newCollaborationMessages: false,
	newInternalMessages: null,
	newInternalMessagesRaw: false,
	newClientMessages: null,
	newClientMessagesRaw: false,
	anyNewMessages: false,
	anyUserHasUnreadClientMessages: false,
	hasUsedApp: false,
	hasNotUsedApp: false,
	hasSentMessages: false,
	hasNotSentMessages: false,
	hasSentReview: false,
	hasNotSentReview: false,
	// Ember black magic, auto-sets variables with values from the query param in the href
	queryParams: ['caseIds', 'newClientMessages', 'newInternalMessages'],
	caseIds: null,

	bulkClientInteractions: computed(
		'hasUsedApp',
		'hasNotUsedApp',
		'hasSentMessages',
		'hasNotSentMessages',
		'hasSentReview',
		'hasNotSentReview',
		function () {
			const interactions = {
				hasUsedApp: this.hasUsedApp,
				hasNotUsedApp: this.hasNotUsedApp,
				hasSentMessages: this.hasSentMessages,
				hasNotSentMessages: this.hasNotSentMessages,
				hasSentReview: this.hasSentReview,
				hasNotSentReview: this.hasNotSentReview,
			};
			// return the keys that have a truthy value
			return Object.keys(interactions).filter((k) => interactions[k]);
		},
	),

	filters: computed(
		'clientName',
		'attorneyName',
		'preferences.myCases',
		'paralegalName',
		'caseId',
		'newCollaborationMessages',
		'newInternalMessagesRaw',
		'newClientMessagesRaw',
		'anyNewMessages',
		'bulkTypes',
		'allCases',
		'openAndActive',
		'onHold',
		'onboarding',
		'dateRanges.{startDate,endDate}',
		'bulkStatus',
		'closed',
		'removed',
		'noCaseManagers',
		'permissions.user.view_all_cases',
		'bulkNps',
		'caseIds',
		'hasUsedApp',
		'hasNotUsedApp',
		'hasSentMessages',
		'hasNotSentMessages',
		'mobile.showMobile',
		'searchString.length',
		'targetLanguage',
		'hasSentReview',
		'hasNotSentReview',
		'anyUserHasUnreadClientMessages',
		function () {
			let myCases = this.get('permissions.user.view_all_cases')
				? this.get('mobile.showMobile') && this.get('searchString.length')
					? false
					: this.get('preferences.myCases')
				: true;

			let activeFilters = {};
			const availableFilters = {
				'client.name': this.clientName,
				'client.cell_phone': this.clientName,
				'attorney.name': this.attorneyName,
				my_cases: myCases,
				'paralegal.name': this.paralegalName,
				readable_id: this.caseId,
				case_ids: this.caseIds,
				closed: this.closed,
				new_collaboration_messages: this.newCollaborationMessages,
				new_internal_messages: this.newInternalMessagesRaw,
				new_client_messages: this.newClientMessagesRaw,
				any_new_messages: this.anyNewMessages,
				any_user_has_unread_client_messages: this.permissions.get(
					'user.filter_firm_unread_messages',
				)
					? this.anyUserHasUnreadClientMessages
					: false,
				'case_type.id': this.bulkTypes,
				most_recent_nps: this.bulkNps,
				all:
					this.get('permissions.user.view_all_cases') &&
					this.get('mobile.showMobile') &&
					this.get('searchString.length')
						? true
						: this.allCases,
				open_and_active: this.openAndActive,
				on_hold: this.onHold,
				start_date: moment(this.dateRanges.startDate).isValid()
					? moment(this.get('dateRanges.startDate'))
							.format('YYYY-MM-DD')
							.toString()
					: false,
				end_date: moment(this.dateRanges.endDate).isValid()
					? moment(this.get('dateRanges.endDate'))
							.format('YYYY-MM-DD')
							.toString()
					: false,
				'case_status.number': this.bulkStatus,
				currently_onboarding: this.onboarding,
				deleted_at: this.removed,
				case_paralegal: this.noCaseManagers,
				target_language: this.targetLanguage,
			};

			//* Check is potentially searching by client phone number
			if (this.clientName && /[0-9]/g.test(this.clientName)) {
				delete availableFilters['client.name'];
			} else {
				delete availableFilters['client.cell_phone'];
			}
			for (let filterProperty in availableFilters) {
				let testValue = availableFilters[filterProperty];

				// Don't want to send useless filters like empty arrays
				if (testValue instanceof Array && testValue.length < 1) {
					testValue = false;
				}

				if (testValue) {
					// API endpoint for sendBulkMessage yells at me if the boolean value on 'my_cases' isn't a string 😢, luckily fetchNewData accepts it as a string or a boolean
					const formattedValue =
						typeof testValue == 'boolean' ? testValue.toString() : testValue;

					activeFilters[filterProperty] = formattedValue;
				}
			}
			// if both hasUsedApp and hasNotUsedApp are selected, don't bother setting activeFilters.last_opened_is_null and just show all cases as this is effectively the same thing
			if (this.hasUsedApp && this.hasNotUsedApp) {
				delete activeFilters.last_opened_is_null;
			} else if (this.hasUsedApp) {
				activeFilters.last_opened_is_null = false;
			} else if (this.hasNotUsedApp) {
				activeFilters.last_opened_is_null = true;
			}

			// if both hasSentMessages and hasNotSentMessages are selected, don't bother setting activeFilters.any_client_sent_message and just show all cases as this is effectively the same thing
			if (this.hasSentMessages && this.hasNotSentMessages) {
				delete activeFilters.any_client_sent_message;
			} else if (this.hasSentMessages) {
				activeFilters.any_client_sent_message = true;
			} else if (this.hasNotSentMessages) {
				activeFilters.any_client_sent_message = false;
			}

			// if both hasSentReview and hasNotSentReview are selected, don't bother setting activeFilters.review_message_sent and just show all cases as this is effectively the same thing
			if (this.hasSentReview && this.hasNotSentReview) {
				delete activeFilters.review_message_sent;
			} else if (this.hasSentReview) {
				activeFilters.review_message_sent = true;
			} else if (this.hasNotSentReview) {
				activeFilters.review_message_sent = false;
			}
			return activeFilters;
		},
	),
	firm: alias('currentUser.user.firm'),
	usesClio: alias('currentUser.user.firm.usesClio'),
	showImportCasesDropdown: false,
	showUploadCsvModal: false,
	casesCsvTemplateUrl: `https://s3.amazonaws.com/case.status.${s3Env}/cases_format_template.csv`,

	preferenceFilterOptions: computed(
		'preferences.myCases',
		'closed',
		'allCases',
		'openAndActive',
		'onHold',
		'onboarding',
		'removed',
		'noCaseManagers',
		'company',
		'permissions.user.view_all_cases',
		function () {
			const caseLabel = capitalize(dynamicCaseLabel([this.company, true]));

			return [
				{
					value: `All ${caseLabel}`,
					checked: this.allCases,
					disabled: !this.permissions.get('user.view_all_cases'),
				},
				{
					value: `My ${caseLabel}`,
					checked: this.permissions.get('user.view_all_cases')
						? this.preferences.get('myCases')
						: true,
					disabled: !this.permissions.get('user.view_all_cases'),
				},
				{
					value: `Open and Active ${caseLabel}`,
					checked: this.openAndActive,
				},
				{
					value: `Closed ${caseLabel}`,
					checked: this.closed,
				},
				{
					value: `${caseLabel} On Hold`,
					checked: this.onHold,
				},
				{
					value: 'No Case Managers',
					checked: this.noCaseManagers,
				},
				{
					value: `Onboarding ${caseLabel}`,
					checked: this.onboarding,
				},
				{
					value: `Removed ${caseLabel}`,
					checked: this.removed,
				},
			];
		},
	),
	searchTypeOptions: computed('firm.isLawFirm', function () {
		const searchTypeOptions = ['client', 'attorney', 'caseId'];
		if (this.firm.get('isLawFirm')) searchTypeOptions.splice(2, 0, 'paralegal');

		return searchTypeOptions;
	}),

	showBulksActionOptionsModal: false,

	paramsCheck: observer('permissions.user.view_all_cases', function () {
		// If query params adjust the myCases preference, but also check for permissions to view all cases
		if (
			window.location.href.includes('?') &&
			this.get('permissions.user.view_all_cases')
		) {
			this.set('preferences.myCases', false);
		}
	}).on('init'),

	ajax: service(),

	fetchNewData() {
		// Redirect client users to the client version of the app
		if (this.get('currentUser.user.constructor.modelName') == 'client')
			return this.replaceRoute('app.client.cases');

		//* Set the isLoading state property to true
		this.set('isLoading', true);

		//* Reset the array of any selectedCases or deselectedCases
		this.set('selectedCases', []);
		this.set('deselectedCases', []);

		//* Disable case select while loading.
		this.set('disableCaseSelect', true);

		//* Reset the selectAllCases state property to false
		this.set('selectAllCases', false);

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			page: {},
		};

		//* Set the filter property on the queryParams to our computed filters property
		queryParams.filter = this.filters;

		//* Set the page Object's size property; eventually we should allow them to be able to choose different page sizes
		queryParams.page.size = casesPerPage;

		//* Set the sort on the queryParams to our current sortProperty
		queryParams.sort = this.sort;

		//* Set the queryParams
		this.set('queryParams', queryParams);

		//* Set cache-buster since this is new data being called;
		this.set('ajax.noCache', true);

		//* Query the store for cases using the queryParams
		this.store.query('case', queryParams).then((cases) => {
			//* Clear the model since this is all new data
			this.model.clear();

			//* Set a constant as the array of the response
			const caseArray = cases.toArray();

			//* if the caseArray is empty, then disable the ability to select all on the table by using a state property called disableCaseSelect
			caseArray.length == 0
				? this.set('disableCaseSelect', true)
				: this.set('disableCaseSelect', false);

			//* Clear model again to prevent duplicates
			this.model.clear();

			//* Push the new array's objects into the newly cleared model
			this.model.pushObjects(caseArray);

			//* Set the meta property using the meta from the response
			this.set('meta', cases.get('meta'));

			//* Set the totalCase count since it gets wiped at some point.
			this.set('totalCases', cases.get('meta.total_results'));

			//* Reset the page count to 1
			this.set('page', 1);

			//* In the next runloop we will set the isLoading state property to false and check for callbacks
			next(() => {
				this.set('isLoading', false);

				//* Check if there is a set newCasesFetchCallback and that it is a function
				if (
					this.newCasesFetchCallback &&
					typeof this.newCasesFetchCallback == 'function'
				) {
					//* Call the newCasesFetchCallback
					this.newCasesFetchCallback();

					//* Then in the next runloop reset the newCasesFetchCallback property to null
					next(() => {
						this.set('newCasesFetchCallback', null);
					});
				}

				//* Check if there is a set casesFetchCallback and that it is a function
				if (
					this.casesFetchCallback &&
					typeof this.casesFetchCallback == 'function'
				) {
					//* Call the casesFetchCallback
					this.casesFetchCallback();

					//? This callback doesn't get reset since it is intended to be
					//? called after each fetch for cases whether new or for more
				}

				if (shouldShowMoreData()) {
					this.send('fetchMoreCases');
				}
			});
		});
	},

	showSyncModal: false,
	showInactiveCasesPopup: false,
	showInactiveCasesPopupObserver: observer(
		'showInactiveCasesPopup',
		'model.[]',
		function () {
			//* Only try to show the tip banner if the showInactiveCasesPopup property is true
			if (this.showInactiveCasesPopup) {
				//* Check the tipShowCount property to make sure that the tip hasn't already been shown or is showing
				//* Also check that the model have cases in it to be sure there are cases as well as to let the page finish loading
				if (this.tipShowCount || !this.get('model.length')) return;
				//* Don't show the tip on mobile web app
				if (this.get('mobile.showMobile')) return;

				//* Set the tipShowCount property to 1 so that way the notif banner isn't called sever times after the first
				this.set('tipShowCount', 1);

				//* Clear all current notif banners before showing the tip banner, (There really shouldn't be any banners to clear, but just to be safe)
				this.notifications.clearAll();
			}
		},
	).on('init'),

	bulkOnboardCasesCTA: function () {
		const myCases = this.get('preferences.myCases');
		const onboarding = this.onboarding;
		const cases = this.model || [];

		if (myCases && onboarding && Array.isArray(cases) && cases.length) {
			//* If the filters are correct and there are cases that match them then
			//* show a clickable info notification that tells them how many onboarding
			//* cases they have and let them know they can click the notif to invite them
			this.notifications.info(
				`You have ${
					this.meta.total_results ? this.meta.total_results : ''
				} onboarding cases. Click here to invite them.`,
				{
					//* When the notif is clicked:
					onClick: () => {
						//* Set the selectAllCases to true
						this.set('selectAllCases', true);

						//* Show the Bulk Message Modal
						this.set('showBulkMessageModal', true);

						//* Clear all current notif banners so it doesn't look stuck or like it's not doing anything
						this.notifications.clearAll();
					},
				},
			);
		} else if (myCases && onboarding && cases.length == 0) {
			//* Show a success notification to congratulate them for having onboarded all of their cases
			this.notifications.success(`All of your cases have been onboarded! 🎉`, {
				autoClear: true, //* Allow it to autoClear
				clearDuration: 10000, //* autoClear after 10 seconds
			});
		}
	},

	//* Callback function property that is called at the end of this.fetchNewData, reset to null after it's ran
	newCasesFetchCallback: null,

	//* Callback function property that is called at the end of the fetchMoreCases action, reset to null after it's ran
	moreCasesFetchCallback: null,

	//* Callback function property that is called at the end of the this.fetchNewData AND at the end of the fetchMoreCases action
	//* This callback does not get set to null after running and will remain callable until some other process sets it otherwise
	casesFetchCallback: null,

	actions: {
		completeProfile() {
			this.set('showWelcomeModal', false);
			this.transitionToRoute('app.firm.settings.account');
		},

		toggleShowAddCaseModal() {
			this.toggleProperty('showAddCaseModal');
		},

		toggleShowImportCasesDropdown() {
			this.toggleProperty('showImportCasesDropdown');
		},

		toggleShowUploadCsvModal() {
			this.toggleProperty('showUploadCsvModal');
		},

		toggleShowWelcomeModal() {
			this.toggleProperty('showWelcomeModal');
		},

		toggleIframeCaseFilters() {
			this.toggleProperty('showIframeCaseFilters');
		},

		closeImportResultModal() {
			this.set('showImportResultModal', false);
			this.set('importResult', null);
		},

		fetchMoreCases() {
			//* Check to make sure there are more pages to fetch and that we are not currently fetching content
			if (this.get('meta.total_pages') > this.page && !this.isLoading) {
				//* Set the isLoading state property to true
				this.set('isLoading', true);

				//* Increment the current page number
				this.incrementProperty('page');

				//* Set a constant for the current queryParams
				const queryParams = this.queryParams;

				//* Update the requested page number on the queryParams
				queryParams.page.number = this.page;

				//* Query the store for cases using our updated queryParams
				this.store.query('case', queryParams).then((cases) => {
					//* Update the meta property using the meta on the response
					this.set('meta', cases.get('meta'));

					//* Set a constant to the array from the response
					const caseArray = cases.toArray();

					//* Push the case Objects from the response array into the current model
					this.model.pushObjects(caseArray);

					//* In the next runloop set the isLoading state property to false and check for callbacks
					next(() => {
						this.set('isLoading', false);

						//* Check if there is a set moreCasesFetchCallback and that it is a function
						if (
							this.moreCasesFetchCallback &&
							typeof this.moreCasesFetchCallback == 'function'
						) {
							//* Call the moreCasesFetchCallback
							this.moreCasesFetchCallback();

							//* Then in the next runloop reset the moreCasesFetchCallback property to null
							next(() => {
								this.set('moreCasesFetchCallback', null);
							});
						}

						//* Check if there is a set casesFetchCallback and that it is a function
						if (
							this.casesFetchCallback &&
							typeof this.casesFetchCallback == 'function'
						) {
							//* Call the casesFetchCallback
							this.casesFetchCallback();

							//? This callback doesn't get reset since it is intended to be
							//? called after each fetch for cases whether for more or new
						}
					});
				});
			}
		},

		importCasesButtonPressed() {
			this.notifications.clearAll();
			this.toggleProperty('showImportCasesDropdown');
			const result = defer();

			this.set('importingCases', true);
			this.send('importCases', result);
			result.promise
				.then((response) => {
					this.set('importResult', response);
					this.set('showImportResultModal', true);
					this.set('importingCases', false);
				})
				.catch(() => {
					this.set('importingCases', false);
				});
		},

		updateSort(sortColumn, sortDirection) {
			const sortString = `${sortDirection}${sortColumn.underscore()}`;
			this.set('sort', sortString);
		},

		sendSaveClient(client, results) {
			this.send('saveClient', client, results);
		},

		selectCase(targetCase) {
			if (!targetCase || this.disableCaseSelect) return;
			if (targetCase === 'all') {
				if (
					this.selectedCases?.length > 0 ||
					this.deselectedCases?.length > 0
				) {
					this.set('selectAllCases', false);
					this.set('selectedCases', []);
					this.set('deselectedCases', []);
				} else {
					this.toggleProperty('selectAllCases');
				}
			} else if (!this.selectAllCases) {
				targetCase = Number(targetCase);
				if (!targetCase) return;

				let casesSelected = [...this.selectedCases];
				if (casesSelected.includes(targetCase)) {
					casesSelected.splice(casesSelected.indexOf(targetCase), 1);
				} else {
					casesSelected.push(targetCase);
				}
				this.set('selectedCases', casesSelected);
			}
			resetTableLocation(500);
		},

		deselectCase(targetCase) {
			//* Do nothing if there is no targetCase passed,
			//* or if selecting is disabled, or selectAllCases is not true
			if (!targetCase || this.disableCaseSelect || !this.selectAllCases) return;
			if (targetCase === 'all') {
				if (
					this.selectedCases?.length > 0 ||
					this.deselectedCases?.length > 0
				) {
					this.set('selectAllCases', false);
					this.set('selectedCases', []);
					this.set('deselectedCases', []);
				} else {
					this.toggleProperty('selectAllCases');
				}
			} else if (this.selectAllCases) {
				targetCase = Number(targetCase);
				if (!targetCase) return;
				let casesDeselected = this.deselectedCases
					? [...this.deselectedCases]
					: [];
				if (casesDeselected.includes(targetCase)) {
					casesDeselected.splice(casesDeselected.indexOf(targetCase), 1);
				} else {
					casesDeselected.push(targetCase);
				}
				this.set('deselectedCases', casesDeselected);
			}
			resetTableLocation(500);
		},

		uploadFile(evt) {
			const result = defer();
			let file;
			if (evt && evt.blob) {
				file = evt.blob;
				const errorMsg = createErrorIfFileTooBig([
					file.size,
					this.maxUploadFileMB,
				]);
				if (errorMsg != null) {
					this.setProperties({
						errors: [errorMsg],
						importingCases: false,
					});
					return;
				}
				this.set('importingCases', true);
				this.send('sendFile', file, result);
			}
			result.promise
				.then((res) => {
					this.set('importingCases', false);
					evt.set('queue.files', []);
					this.toggleProperty('showUploadCsvModal');
					this.set('bulkMessageSuccessResponse', res.message);
					this.set('showBulkMessageSuccessModal', true);
				})
				.catch((err) => {
					this.setProperties({
						errors: [
							'Error occurred with your file. If issue continues, please contact administrator info@casestatus.com',
						],
						importingCases: false,
					});
					captureException(err);
				});
		},

		toggleMoreBulkActions: function () {
			this.toggleProperty('hideMoreBulkActions');
		},

		togglePreferenceOption(option) {
			const caseLabel = capitalize(dynamicCaseLabel([this.company, true]));
			if (option != `All ${caseLabel}`) {
				this.set('allCases', false);
			}
			switch (option) {
				case `All ${caseLabel}`:
					this.toggleProperty('allCases');
					// if all cases set to true negate all other filters
					if (this.allCases) {
						// Set all the other filters to false
						this.setProperties({
							'preferences.myCases': false,
							closed: false,
							openAndActive: false,
							onHold: false,
							onboarding: false,
							removed: false,
							noCaseManagers: false,
						});
					}
					break;

				case `My ${caseLabel}`:
					this.toggleProperty('preferences.myCases');
					break;

				case `Open and Active ${caseLabel}`:
					this.toggleProperty('openAndActive');
					// if we only want to see open and active cases, negate some conflicting filters appropriately
					if (this.openAndActive) {
						this.setProperties({
							closed: false,
							// the onboarding filter shows inactive cases (which conflicts w/ the open_and_active filter)
							onboarding: false,
							// the removed filter shows deleted cases (which conflicts w/ the open_and_active filter)
							removed: false,
						});
					}
					break;

				case `Closed ${caseLabel}`:
					this.toggleProperty('closed');
					if (this.closed) {
						this.setProperties({
							openAndActive: false,
						});
					}
					break;

				case `${caseLabel} On Hold`:
					this.toggleProperty('onHold');
					break;

				case `Onboarding ${caseLabel}`:
					this.toggleProperty('onboarding');
					if (this.onboarding) {
						this.setProperties({
							openAndActive: false,
						});
					}
					break;

				case `Removed ${caseLabel}`:
					this.toggleProperty('removed');
					if (this.removed) {
						this.setProperties({
							openAndActive: false,
						});
					}
					break;

				case 'No Case Managers':
					this.toggleProperty('noCaseManagers');
					break;
			}
		},
		toggleShowSyncModal() {
			if (this.showSyncModal) {
				this.fetchNewData();
			}
			this.toggleProperty('showSyncModal');
		},

		filterUpdateCallback() {
			// If queryParams are present, clear them
			if (window.location.href.includes('?')) {
				this.set('caseIds', null);
				this.set('newClientMessages', false);
				this.set('newInternalMessages', false);
			}
		},

		toggleShowBulksActionOptionsModal() {
			this.toggleProperty('showBulksActionOptionsModal');
		},

		updateClientInteractionFilters(CIFilters) {
			this.setProperties(CIFilters);
		},

		updateFilters(filters, options) {
			if (ValidateData(filters, 'object')) {
				this.setProperties(filters);
			}

			handleOptionsCallbacks(options);
		},
		clearFilters() {
			this.setProperties({
				bulkTypes: [],
				bulkStatus: [],
				bulkMsg: [],
				bulkNps: [],
				hasUsedApp: false,
				hasNotUsedApp: false,
				hasSentMessages: false,
				hasNotSentMessages: false,
				hasSentReview: false,
				hasNotSentReview: false,
				dateRanges: { startDate: null, endDate: null },
				newCollaborationMessages: false,
				newInternalMessagesRaw: false,
				newClientMessagesRaw: false,
				anyNewMessages: false,
				searchString: '',
				targetLanguage: null,
				anyUserHasUnreadClientMessages: false,
			});
		},
		updateTargetLanguageFilters(TLFilters) {
			this.setProperties(TLFilters);
		},
	},
});
