import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span>\n\t<ToggleSwitch @disabled={{true}} @label='Request Appointment Confirmation'>\n\t\t<a target='_blank' href={{upgradeUrl}} style='margin-left: 0.5rem'>\n\t\t\t<FaIcon @icon='lock' style='color: darkgray;' />\n\t\t</a>\n\t</ToggleSwitch>\n</span>", {"contents":"<span>\n\t<ToggleSwitch @disabled={{true}} @label='Request Appointment Confirmation'>\n\t\t<a target='_blank' href={{upgradeUrl}} style='margin-left: 0.5rem'>\n\t\t\t<FaIcon @icon='lock' style='color: darkgray;' />\n\t\t</a>\n\t</ToggleSwitch>\n</span>","moduleName":"case-status/components/appointment-confirmation-request-disabled.hbs","parseOptions":{"srcName":"case-status/components/appointment-confirmation-request-disabled.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getUpgradeLink } from '../constants/urls';

export default class AppointmentConfirmationRequestDisabledComponent extends Component {
	@service currentUser;

	constructor() {
		super(...arguments);
	}

	get upgradeUrl() {
		return getUpgradeLink(this.currentUser.user.firm.get('firmSettings.firmSettings'));
	}
}