/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-get */
/** @format */

import { A } from '@ember/array';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Errors from '../../../constants/errors';
import ENV from 'case-status/config/environment';
import { captureException } from 'logrocket';
import { defer } from 'rsvp';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { debounce } from '@ember/runloop';
import moment from 'moment';

export default Route.extend({
	notifications: service(),
	session: service(),
	welcome: service(),
	store: service(),
	ajax: service(),
	currentUser: service('current-user'),
	company: service(),
	thirdParty: service(),
	router: service(),
	chromeExtension: service(),
	permissions: service(),
	queryParams: {
		caseIds: null,
		newClientMessages: false,
		newInternalMessages: false,
	},

	beforeModel() {
		if (
			this.thirdParty.integrationType &&
			this.thirdParty.integrationId &&
			this.thirdParty.shouldRedirect
		) {
			this.replaceWith(`app.third_party.case`, this.thirdParty.integrationId);
		} else {
			// let the chromeExtension know the user has reached a page that displays unread messages
			this.chromeExtension.sendLoggedIn();
		}
	},

	model: function (params) {
		// TODO: This isn't setup properly and was circumnavigated with a controller method (fetchNewData and fetchMoreData). Making the page load time slower. This needs to be reworked using the params and controller queryParams properly like here: https://emberigniter.com/pagination-in-ember-with-json-api-backend/#setting-up-our-ember-app, this will probably help with the issues we have with filters and the actual queryParam issues we've been experienceing.
		this.set('params', params);
		return A([]);
	},

	setupController(controller) {
		this._super(...arguments);
		debounce(controller, controller.fetchNewData, 500);

		const lastTimeShownInactiveCasesPopupDate = controller.get(
			'preferences.lastTimeShownInactiveCasesPopupDate',
		);
		const daysSinceShownInactiveCasesPopup = lastTimeShownInactiveCasesPopupDate
			? moment().diff(lastTimeShownInactiveCasesPopupDate, 'days')
			: 1000;

		// Only show the popup on Tuesdays and if we haven't shown it today
		const showInactiveCasesPopup =
			moment().day() === 2 && daysSinceShownInactiveCasesPopup > 1;

		if (showInactiveCasesPopup) {
			controller.set(
				'preferences.lastTimeShownInactiveCasesPopupDate',
				moment(),
			);
		}

		this.store
			.findAll('language')
			.then((res) => {
				controller.set('languages', res);
			})
			.catch((err) => {
				void err;
			});

		this.permissions.fetchDynamicFirmSettings({
			setting: 'recommended_response_feature_case_quota,case_activation_quota',
		});

		controller.setProperties({
			newClientMessagesRaw: Boolean(this.params.newClientMessages),
			newInternalMessagesRaw: Boolean(this.params.newInternalMessages),
			showInactiveCasesPopup,
			bulkFilterOptions: [
				'practice area',
				'date',
				'status',
				'nps',
				'message',
				'client interactions',
			],
			//* active filters
			bulkTypes: controller.bulkTypes || [],
			bulkStatus: controller.bulkStatus || [],
			bulkMsg: controller.bulkMsg || [],
			bulkNps: controller.bulkNps || [],
			dateRanges: controller.dateRanges || { startDate: null, endDate: null },
			hasUsedApp: controller.hasUsedApp || false,
			hasNotUsedApp: controller.hasNotUsedApp || false,
			hasSentMessages: controller.hasSentMessages || false,
			hasNotSentMessages: controller.hasNotSentMessages || false,
			hasSentReview: controller.hasSentReview || false,
			hasNotSentReview: controller.hasNotSentReview || false,
			targetLanguage: controller.targetLanguage || null,
			anyUserHasUnreadClientMessages:
				controller.anyUserHasUnreadClientMessages || false,
			//* end active filters
			selectedCases: [],
			deselectedCases: [],
			clientInteractionsFilterOptions: [
				{
					value: 'Has used the app',
					checked: controller.hasUsedApp,
					classNames: 'filter-lg',
					param: 'hasUsedApp',
				},
				{
					value: 'Has not used the app',
					checked: controller.hasNotUsedApp,
					classNames: 'filter-lg',
					param: 'hasNotUsedApp',
				},
				{
					value: 'Has sent messages',
					checked: controller.hasSentMessages,
					classNames: 'filter-lg',
					param: 'hasSentMessages',
				},
				{
					value: 'Has not sent messages',
					checked: controller.hasNotSentMessages,
					classNames: 'filter-lg',
					param: 'hasNotSentMessages',
				},
			],
			statusTypes: [
				{
					value: 'Stage 1',
					param: 1,
				},
				{
					value: 'Stage 2',
					param: 2,
				},
				{
					value: 'Stage 3',
					param: 3,
				},
				{
					value: 'Stage 4',
					param: 4,
				},
				{
					value: 'Stage 5',
					param: 5,
				},
				{
					value: 'Stage 6',
					param: 6,
				},
				{
					value: 'Stage 7',
					param: 7,
				},
				{
					value: 'Stage 8',
					param: 8,
				},
				{
					value: 'Stage 9',
					param: 9,
				},
				{
					value: 'Stage 10',
					param: 10,
				},
				{
					value: 'Stage 11',
					param: 11,
				},
				{
					value: 'Stage 12',
					param: 12,
				},
			],
			messageFilterOptions: [
				{
					value: 'Has any unread messages',
					imgSrc: 'img/new-messages.svg',
					alt: 'new messages',
					checked: controller.anyNewMessages,
					classNames: 'filter-lg',
					param: 'anyNewMessages',
				},
				{
					value: 'Has unread client messages',
					imgSrc: 'img/new-messages.svg',
					alt: 'new messages',
					checked: Boolean(
						this.params.newClientMessages
							? this.params.newClientMessages
							: controller.newClientMessagesRaw,
					),
					classNames: 'filter-lg',
					param: 'newClientMessagesRaw',
				},
				{
					value: 'Has unread internal messages',
					imgSrc: 'img/new-messages.svg',
					alt: 'new messages',
					checked: Boolean(
						this.params.newInternalMessages
							? this.params.newInternalMessages
							: controller.newInternalMessagesRaw,
					),
					classNames: 'filter-lg',
					param: 'newInternalMessagesRaw',
				},
				{
					value: 'Has unread collaboration messages',
					imgSrc: 'img/new-messages.svg',
					alt: 'new messages',
					checked: controller.newCollaborationMessages,
					classNames: 'filter-lg',
					param: 'newCollaborationMessages',
				},
			],
		});

		controller.set('showAddCaseModal', false);
		controller.setProperties({
			caseId: null,
			caseIdParam: null,
			newClientMessages: null,
			newInternalMessages: null,
			messageTemplateType: null,
		});

		if (this.get('welcome.firstTime')) {
			controller.set('showWelcomeModal', true);
			this.set('welcome.firstTime', false);
		}
	},

	postJSON({ url = '', data = {}, result = defer() }) {
		this.ajax
			.post(url, {
				type: 'POST',
				contentType: 'application/json',
				data,
			})
			.then((res) => {
				result.resolve(res);
			})
			.catch((err) => {
				result.reject(err);
				captureException(err);
			});
	},

	actions: {
		saveClient(client, results) {
			client
				.save()
				.then((client) => results.resolve(client))
				.catch((errors) => {
					// currently server throws a 500 error on invalid phone numbers
					if (errors.errors.length > 0 && errors.errors[0].status === 500) {
						errors.errors[0].detail = 'Phone number exists or is invalid';
					}

					results.reject(errors);
				});
		},

		setCaseTypes(types, result) {
			this.controller.set('caseTypesSuccess', false);
			this.controller.set('caseTypesErrors', null);

			const firmId = this.get('currentUser.user.firm.id');
			const ids = { ids: types };

			const data = JSON.stringify(ids);

			const url = `${ENV.host}/firms/${firmId}/case_types_with_statuses_from_templates`;

			this.ajax
				.post(url, {
					data: data,
				})
				.then(() => {
					result.resolve();
				})
				.catch((errors) => {
					result.reject(Errors.mapResponseErrors(errors));
				});
		},

		addCase(theCase, clients, showAddCaseModal, result) {
			theCase.set('clients', clients);

			theCase
				.save()
				.then(() => {
					const controller = this.controllerFor('app.firm.cases');
					controller.set('showAddCaseModal', showAddCaseModal);
					this.notifications.success(
						`Added ${dynamicCaseLabel([this.company])} successfully!`,
						{
							autoClear: true,
							clearDuration: 7000,
							cssClasses: 'notification--success',
						},
					);
					controller.fetchNewData();
					result.resolve();
				})
				.catch((response) => {
					result.reject(Errors.mapResponseErrors(response));
				});
		},

		importCases(result) {
			const url = `${ENV.host}/cases/import`;
			this.ajax
				.post(url, {
					contentType: 'application/json',
				})
				.then((response) => {
					result.resolve(response);
					const controller = this.controllerFor('app.firm.cases');
					controller.fetchNewData();
				})
				.catch((response) => {
					result.reject(response);
				});

			return result.promise;
		},

		transitionToCase(id) {
			if (this.thirdParty.showIframeUi) {
				//  iframe experience
				this.router.transitionTo('app.firm.case.messages', id);
			} else {
				// webapp experience
				this.router.transitionTo('app.firm.case.info', id);
			}
		},

		logout() {
			this.currentUser.logout();
		},

		sendFile(file, result) {
			const url = `${ENV.host}/files/case_import`;
			const data = new FormData();
			data.append('file', file);

			const config = {
				type: 'POST',
				contentType: false,
				data,
				processData: false,
				mimeType: 'multipart/form-data',
			};

			this.ajax
				.post(url, config)
				.then((res) => result.resolve(res))
				.catch((err) => result.reject(err));
		},

		sendInvite(caseId, result = defer()) {
			let url = ENV.host + '/cases/send_down_link/' + caseId;
			this.postJSON({ url, data: null, result });
		},
	},
});
