/** @format */

import { defer, reject, resolve } from 'rsvp';
import Controller from '@ember/controller';
import { observer } from '@ember/object';
import { not } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import ValidationErrors from '../constants/errors';
import ENV from 'case-status/config/environment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Errors from '../constants/errors';
import { next } from '@ember/runloop';

const Validations = buildValidations({
	login: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Email Address',
	}),

	password: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Password',
		disabled: not('model.passwordValidationEnabled'),
	}),
});
export default Controller.extend(Validations, {
	queryParams: ['integrationType', 'integrationId', 'redirectTo', 'username', 'pin', 'is_platform_redirect'],
	username: null,
	pin: null,
	integrationType: null,
	integrationId: null,
	redirectTo: null,
	integrationObserver: observer(
		'integrationType',
		'integrationId',
		function () {
			// re-logging in from the chrome extension 
			const previousIntType =
				window.parent.location !== window.location
					? this.thirdParty.get('integrationType')
					: null;
			this.thirdParty.set(
				'integrationType',
				this.integrationType || previousIntType,
			);
			this.thirdParty.set('integrationId', this.integrationId);
			this.thirdParty.set('hasParams', true);
		},
	).on('init'),
	redirectionObserver: observer('redirectTo', function () {
		this.thirdParty.set('redirectTo', this.redirectTo);
	}).on('init'),

	login: null,
	password: null,
	ajax: service(),
	thirdParty: service(),
	passwordValidationEnabled: false,
	inputType: 'login',
	notifications: service(),
	serviceWorker: service('service-worker'),
	serverDown: observer('serviceWorker.serverDownLogin', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('serverDownLogin')) {
			this.notifications.error(
				'App currently having trouble connecting. Try again in a few minutes.',
				{
					cssClasses: 'notification--failure',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),
	internetDown: observer('serviceWorker.internetDownLogin', function () {
		// If we start to get positive response from server clear message
		if (this.serviceWorker.get('internetDownLogin')) {
			this.notifications.error(
				'App currently having trouble connecting to internet. Please check your connection.',
				{
					cssClasses: 'notification--error',
				},
			);
		} else {
			this.notifications.clearAll();
		}
	}).on('init'),

	usernameObserver: observer('username', function () {
		this.set('login', this.username);
	}).on('init'),

	pinObserver: observer('pin', function () {
		this.set('password', this.pin);
	}).on('init'),

	platformRedirectObserver: observer('is_platform_redirect', 'login', 'password', function () {
		if (this.is_platform_redirect && this.login && this.password) {
			const result = defer();
			next(() => {
				this.send('login', this.login, this.password, result);
			})
		}
	}).on('init'),

	actions: {
		loginButtonPressed() {
			this.set('errors', null);

			let login = this.login;
			const password = this.password;
			const errors = this.get('validations.messages');

			this.set('showError', true);
			if (errors.length > 0) {
				return reject();
			} else {
				const isEmail = login.indexOf('@') > -1;
				if (!isEmail) {
					// is phone number
					let phoneNumber = `+1${login}`;
					//Parse and convert the number to E164 format
					let phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
					login = phoneNumberObj.number;
				}
				const result = defer();
				this.send('login', login, password, result);
				return result.promise;
			}
		},
		checkLoginType() {
			this.set('errors', null);

			const login = this.login;
			const errors = this.get('validations.messages');

			this.set('showError', true);
			if (errors.length > 0) {
				return reject();
			} else {
				const result = defer();
				const isEmail = login.indexOf('@') > -1;
				if (isEmail) {
					// show password input
					this.set('showError', false);
					this.set('inputType', 'password');
					this.set('passwordValidationEnabled', true);
					return resolve();
				} else {
					const url = `${ENV.host}/token/request_verification_code`;
					let phoneNumber = login;
					// add country code if not typed
					if (phoneNumber.indexOf('+') === -1) {
						phoneNumber = `+1${phoneNumber}`;
					}

					//Parse and convert the number to E164 format
					let phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
					const data = {
						phone_number: phoneNumberObj.number,
					};

					this.ajax
						.post(url, {
							type: 'POST',
							contentType: 'application/json',
							data,
						})
						.then((resp) => {
							this.set('successMessage', resp.message || `Success!`);
							this.set('inputType', 'pin');
							result.resolve();
						})
						.catch((resp) => {
							this.set('errors', Errors.mapResponseErrors(resp));
							result.reject();
						});
				}
				return result.promise;
			}
		},
		backToStart() {
			this.setProperties({
				errors: null,
				showError: false,
				login: null,
				password: null,
				inputType: 'login',
				passwordValidationEnabled: false,
			});
		},
	},
});
